import { render, staticRenderFns } from "./ModalEditBanner.vue?vue&type=template&id=967b9642&scoped=true"
import script from "./ModalEditBanner.vue?vue&type=script&lang=js"
export * from "./ModalEditBanner.vue?vue&type=script&lang=js"
import style0 from "./ModalEditBanner.vue?vue&type=style&index=0&id=967b9642&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "967b9642",
  null
  
)

export default component.exports