<template>
    <v-dialog transition="dialog-top-transition" max-width="1100" v-model="showDialog" persistent eager>
      <v-card class="upload-modal">
        <!-- Header -->
        <v-card-title class="modal-header">
          <h3 class="headline">Gestor de Banners</h3>
          <v-spacer></v-spacer>
        </v-card-title>
  
        <!-- Contenido principal -->
        <v-card-text class="content-wrapper py-6">
          <v-row>
           <!-- Configuraciones -->
                <v-col cols="12" md="5" class="config-section">
                <div class="config-group">
                    <h4 class="mb-4">Configuraciones de Tipo</h4>
                    <!-- Tipo de banner -->
                    <v-select
                    v-model="bannerType"
                    :items="bannerTypes"
                    :rules="rules.required"
                    color="#8061c2" 
                    label="Categoria de banner"
                    outlined
                    dense
                    ></v-select>

                    <!-- Edad -->
                        <v-select
                        :items="agesTypes"
                        v-model="ageRange"
                        :rules="rules.required"
                        color="#8061c2"
                        label="Edad"
                        outlined
                        dense
                        ></v-select>

                    <!-- Género -->
                    <v-select
                    v-model="selectedGender"
                    :items="genders"
                    :rules="rules.required"
                    color="#8061c2"
                    label="Género"
                    outlined
                    dense
                    ></v-select>
                </div>

                <div class="config-group mt-6">
                    <!-- Campos existentes (URL e Idioma) -->

                    <h4 class="mb-4">Configuraciones de Url/Idioma</h4>
                    <v-text-field
                    v-model="commonUrl"
                    :rules="rules.urlRules"
                    color="#8061c2" 
                    label="URL de destino"
                    placeholder="https://ejemplo.com/pagina-destino"
                    outlined
                    dense
                    class="mt-3"
                    ></v-text-field>

                    <v-select
                    v-model="commonLanguage"
                    :items="languages"
                    :rules="rules.required"
                    color="#8061c2" 
                    label="Idioma"
                    outlined
                    dense
                    class="mt-3"
                    ></v-select>

                </div>
                </v-col>
  
            <!-- Zona de carga -->
            <v-col cols="12" md="7">
              <v-card 
                class="drop-zone"
                @drop.prevent="handleDrop"
                @dragover.prevent="dragOver = true"
                @dragleave.prevent="dragOver = false"
                :class="{ 'dragover': dragOver }"
              >
                <v-card-text class="drop-content">
                  <div v-if="files.length === 0" class="empty-state">
                    <v-icon size="90" color="#8061c2">mdi-cloud-upload</v-icon>
                    <p class="subtitle-1 mt-4 mb-1">Arrastra imágenes aquí o</p>
                    <p class="caption  mt-1 mb-0">Formatos soportados: PNG, JPG, GIF, WEBP</p>
                    <p class="caption">Tamaño máximo: 5MB</p>
                    <v-btn 
                      color="#8061c2"
                      outlined
                      class="mt-3"
                      @click="$refs.fileInput.click()"
                    >
                      Seleccionar archivos
                    </v-btn>
                  </div>
  
                  <div v-else>
                    <div class="file-header">
                      <span class="subtitle-1">
                        {{ files.length }} archivo(s) seleccionado(s)
                      </span>
                      <v-btn text small @click="clearAllFiles">
                        Limpiar todo
                      </v-btn>
                    </div>
  
                    <v-list dense class="file-list">
                      <v-list-item 
                        v-for="(file, index) in files" 
                        :key="index"
                        class="file-item"
                      >
                        <v-avatar rounded size="40">
                          <v-img :src="filePreviews[index]"></v-img>
                        </v-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="file-name">
                            {{ file.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="file-size">
                            {{ (file.size / 1024).toFixed(1) }} KB
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-btn icon @click="removeFile(index)">
                          <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
  
        <!-- Acciones -->
        <v-card-actions class="modal-actions">
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="#8061c2" 
            @click="closeModal"
            class="rounded-button"
          >
            Cancelar
          </v-btn>
            <v-btn 
            color="#8061c2" 
            :disabled="!isFormValid" 
            :loading="loadings"
            @click="handleSubmit"
            depressed
            class="rounded-button"
            style="color: white;"
            >
            Subir banners
            </v-btn>
        </v-card-actions>
        <input 
          ref="fileInput" 
          type="file" 
          multiple 
          accept="image/*" 
          hidden 
          @change="handleFileSelect"
        >
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from '@/axios.js';
  export default {
    name: 'ModalUploadBanner',
    props: {
      dialogShow: {
      }
    },
    data: () => ({
      files: [],
      filePreviews: [],
      dragOver: false,
      useCommonUrl: false,
      commonUrl: null,
      useCommonLanguage: false,
      commonLanguage: null,
      ageRange: null,
      bannerType: null,
      selectedGender: null,
      languages: [
        { text: 'Español', value: 'es' },
        { text: 'Inglés', value: 'en' },
        { text: 'Francés', value: 'fr' },
        { text: 'Alemán', value: 'de' },
        { text: 'Portugués', value: 'pt' },
        {text:  'Hindi', value: 'hi'},
        {text: 'Alemán', value: 'de' },
        { text: 'Italiano', value: 'it' },
      ],
      genders: [
        { text: 'Mujer', value: 1},
        { text: 'Hombre', value: 2 },
        { text: 'Trans', value: 3 }
      ],
      bannerTypes: [
        { text: 'Adultos', value: 1 },
        { text: 'Gaming', value: 2 },
        { text: 'Influenciador', value: 3 },
        { text: 'Comercio', value: 4 },
      ],
      agesTypes: [
        { text: '18 - 25 años', value: 1 },
        { text: '26 - 35 años', value: 2 },
        { text: '36 - 50 años', value: 3 },
        { text: '+51 años', value: 4 }
      ],
      rules: {
        required: [v => !!v || 'Este campo es requerido'],
        urlRules: [
          v => !!v || 'La URL es requerida',
          v => /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?(\?.*)?$/.test(v) || 'Ingrese una URL válida'
        ],
      },
      loadings: false

    }),
    computed: {
      showDialog: {
        get() { return this.dialogShow },
        set(value) { this.$emit("update:dialogShow", value) }
      },

      isFormValid() {
        return (
          this.rules.required[0](this.commonUrl) === true &&
          this.rules.urlRules[1](this.commonUrl) === true &&
          this.rules.required[0](this.commonLanguage) === true &&
          this.rules.required[0](this.bannerType) === true &&
          this.rules.required[0](this.ageRange) === true &&
          this.rules.required[0](this.selectedGender) === true &&
          this.files.length > 0
        );
      },
    },
    methods: {
      handleFileSelect(e) {
        this.addFiles(Array.from(e.target.files))
      },
      handleDrop(e) {
        this.dragOver = false
        this.addFiles(Array.from(e.dataTransfer.files))
      },
      addFiles(files) {
        const maxSize = 5242880;
        files.forEach(file => {
          if (!file.type.startsWith('image/')) return
          if(file.size > maxSize) {
            this.$emit('show-error', 'El tamaño de la imagen no debe superar los 5MB');
            return;
          }
          const reader = new FileReader()
          reader.onload = e => this.filePreviews.push(e.target.result)
          reader.readAsDataURL(file)
          this.files.push(file)
        })
      },
      removeFile(index) {
        this.files.splice(index, 1)
        this.filePreviews.splice(index, 1)
      },
      clearAllFiles() {
        this.files = []
        this.filePreviews = []
      },
      clearAllFields() {
        // Limpiar archivos
        this.files = []
        this.filePreviews = []

        // Limpiar campos de configuración
        this.commonUrl = null
        this.commonLanguage = null
        this.ageRange = null
        this.bannerType = null
        this.selectedGender = null
        this.dragOver = false
      },
      closeModal() {
        this.clearAllFields()
        this.showDialog = false
      },
      async handleSubmit() {
        try {
          this.loadings = true
          // Crear un FormData para enviar archivos
          const formData = new FormData()

          formData.append('commonUrl', this.commonUrl)
          formData.append('lang', this.commonLanguage)
          formData.append('bannerTypeId', this.bannerType)
          formData.append('ageRangeId', this.ageRange)
          formData.append('genderId', this.selectedGender)

          this.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file)
          })

          await this.uploadBanners(formData)
          this.$emit('upload-banner', formData)
          this.closeModal()
        } catch (error) {
          this.$emit('show-error', error.message)
        } finally {
          this.loadings = false
        }
      },

      async uploadBanners(formData) {
        try {
          const response = await axios.post('/admin/upload-banners', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          if (response.data.success) {
            this.$emit('show-success', 'Banners subidos exitosamente');
          }

        } catch (error) {
          const message = error.response?.data?.message || 'Error al subir los banners';
          this.$emit('show-error', message);
        }
      },
    },

    beforeDestroy() {
      this.filePreviews.forEach(url => URL.revokeObjectURL(url))
    }
  }
  </script>
  
  <style scoped>
  .modal-header {
    background-color: #8061c2;
    color: white;
    padding: 20px 24px;
  }
  
  .content-wrapper {
    padding: 24px;
  }
  
  .config-section {
    border-right: 1px solid #eee;
    padding-right: 32px;
  }
  
  .config-group {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 16px;
  }
  
  .drop-zone {
    border: 2px dashed #8061c2;
    border-radius: 8px;
    transition: all 0.3s ease;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .drop-zone.dragover {
    background-color: rgba(128, 97, 194, 0.1);
  }
  .empty-state {
      text-align: center;
      height: 100%;
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .file-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .file-list {
    max-height: 430px;
    overflow-y: auto;
    padding-right: 8px;
  }
  
  .file-item {
    background: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px 12px;
  }
  
  .file-name {
    font-weight: 500;
    font-size: 0.875rem;
  }
  
  .file-size {
    font-size: 0.75rem;
    color: #6c757d;
  }
  
  .modal-actions {
    padding: 16px 24px;
    border-top: 1px solid #eee;
  }
  
  .headline {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .age-range {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  margin: 8px 0;
}

.age-range label {
  display: block;
  margin-bottom: 6px;
  font-size: 0.875rem;
}

.drop-content {
    height: 100%;
}

.rounded-button {
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
}
  
</style>