<template>
    <div>
        <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-5 mb-3">
                <v-alert
                    text
                    dense
                    color="#8061c2"
                    border="left"
                    class="pl-5"
                >
                    <h1 class="title mt-1">Banners</h1>
                    <p class="desc mt-0 mb-1">Listado de banners disponibles en la plataforma.</p>
                </v-alert>

                <v-btn outlined color="rgb(255, 87, 34)" @click="openModalUploadBanner()">
                    <v-icon>mdi-view-grid-plus</v-icon> &nbsp;&nbsp; Nuevo Banner
                </v-btn>
                </v-col>
            <!-- Search Bar y Filtros -->
            <v-row class="mx-3">
                <v-col cols="12" md="4" class="px-0 pb-0 mb-0">
                    <v-text-field v-model="searchTable" color="#8061c2" label="Buscar"
                        append-icon="mdi-magnify" single-line hide-details outlined
                        class="mb-0 mx-0 px-0"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" class="px-md-2 px-0 pb-0 mb-0">
                    <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false"
                        :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="Fecha Inicio" readonly v-bind="attrs" v-on="on"
                                outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate"  :min="minAllowedDate"
                            @input="startDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" md="2" class="pr-md-2 px-0 pb-0 mb-0">
                    <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="Fecha Fin" readonly v-bind="attrs" v-on="on"
                                outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" :min="startDate" 
                            @input="endDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" md="2" class="pr-md-2 px-0 pb-0 mb-0">
                    <v-select v-model="sortField" :items="sortFieldOptions" label="Ordenar por" outlined hide-details
                        @change="fetchAdsStats"></v-select>
                </v-col>

                <v-col cols="12" md="2" class="pr-md-2 px-0 pb-0 mb-0">
                    <v-select v-model="sortOrder" :items="sortOrderOptions" label="Orden" outlined hide-details
                        @change="fetchAdsStats"></v-select>
                </v-col>
                <v-col cols="12" md="4" class="pr-md-2 px-0 pt-0 mt-0 pb-10">
                    <v-select v-model="filterStatus" :items="statusOptions" label="Estado" outlined hide-details
                    @change="fetchAdsStats"></v-select>
                </v-col>
                <v-col cols="12" md="2" class="pr-md-2 px-0 pt-0 mt-0 pb-10">
                    <v-select v-model="filterLanguage" :items="languageOptions" label="Idioma" outlined hide-details
                    @change="fetchAdsStats"></v-select>
                </v-col>
                <v-col cols="12" md="2" class="pr-md-2 px-0 pt-0 mt-0 pb-10">
                    <v-select v-model="filterType" :items="typeOptions" label="Tipo" outlined hide-details
                    @change="fetchAdsStats"></v-select>
                </v-col>
                <v-col cols="12" md="2" class="pr-md-2 px-0 pt-0 mt-0 pb-10">
                    <v-select v-model="filterAge" :items="ageOptions" label="Edad" outlined hide-details
                    @change="fetchAdsStats"></v-select>
                </v-col>
                <v-col cols="12" md="2" class="pr-md-2 px-0 pt-0 mt-0 pb-10">
                    <v-select v-model="filterGender" :items="genderOptions" label="Género" outlined hide-details
                    @change="fetchAdsStats"></v-select>
                </v-col>
            </v-row>

            <!-- Tabla de datos -->
            <v-data-table :headers="headers" :items="conversions" :loading="loading" class="elevation-1 mx-3 px-2 py-4"
                :items-per-page="perPage" :server-items-length="totalConversions" :page.sync="page" :footer-props="{
                    itemsPerPageOptions: [50, 100, 200, { text: 'All', value: 0 }],
                    showFirstLastPage: true
                }" @update:page="fetchAdsStats" @update:items-per-page="changePerPage">
                <!-- Columnas y Templates -->

                <template v-slot:item.banner.image_path="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="miniatura clickable-media" @click="showMedia(item.banner.image_path, item.type)"
                            @mouseover="hoveredMedia = item.banner.image_path" @mouseleave="hoveredMedia = null" v-bind="attrs"
                            v-on="on">
                            <template>
                                <img :src="item.banner.image_path" v-if="item.banner.image_path" lazy />
                            </template>
                        </div>
                    </template>
                    <span>Ver</span>
                </v-tooltip>
                </template>



            <template v-slot:item.statistics.payout="{ item }">
                $ {{ item.statistics.payout }} USD
            </template>

                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on"
                                @click="openModalEditBanner(item)">
                                <v-icon>mdi-pencil-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar Banner</span>
                    </v-tooltip> 
                </template> 


                <template v-slot:item.banner.active="{ item }">
                    <v-chip color="green" v-if="item.banner.active == 1" dark small>Activo</v-chip>
                    <v-chip color="red" v-else  dark small>Inactivo</v-chip>
                </template>


                <template v-slot:item.statistics.days_active="{ item }">
                    {{ item.statistics.days_active }} días
                </template>

            </v-data-table>


        <!-- Modal para ampliar el logo -->
        <v-dialog v-model="dialog" max-width="95vh" width="unset" class="rounded-lg dialog-media">
            <div class="media-container">
                <img :src="selectedLogo" class="transition-ease cursor-pointer media" :alt="'Image'" />
            </div>
        </v-dialog>
        </v-col>

        <ModalUploadBanner
        :dialogShow.sync="showModalUploadBanner"
        @show-error="showError($event)"
        @show-success="showSuccess($event)"
        @upload-banner="fetchAdsStats()"
        @close="showModalUploadBanner = false"
        />

        <ModalEditBanner
        :dialogShow.sync="showModalEditBanner"
        :bannerData="selectedBanner"
        @show-error="showError($event)"
        @show-success="showSuccess($event)"
        @updated-banner="fetchAdsStats()"
        @close="showModalEditBanner = false"
        />
    </div>
</template>

<script scoped>
import axios from '@/axios.js';
import moment from 'moment';
import debounce from 'lodash/debounce';
import ModalUploadBanner from '@/components/partials/admin/AdsBanners/ModalUploadBanner.vue';
import ModalEditBanner from '@/components/partials/admin/AdsBanners/ModalEditBanner.vue';

export default {
    name: 'Conversions',
    components: {
        ModalUploadBanner,
        ModalEditBanner
    },
    data() {
        const today = moment().format('YYYY-MM-DD');
        //const threeMonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
        const maxAllowedDate = moment().format('YYYY-MM-DD');
        const minAllowedDate = moment('2025-01-01').format('YYYY-MM-DD');

        return {
            showModalUploadBanner: false,
            showModalEditBanner: false,
            selectedBanner: null,
            conversions: [],
            search: '',
            loading: true,
            dialog: false,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            totalConversions: 0,
            trends: {
                clicks: 0,
                conversions: 0,
                revenue: 0
            },
            sortField: 'payout',
            sortOrder: 'desc',
            sortFieldOptions: [{
                text: 'Clicks',
                value: 'clicks'
            },
            {
                text: 'Conversiones',
                value: 'conversions'
            },
            {
                text: 'Ganancias',
                value: 'payout'
            },
            ],
            sortOrderOptions: [{
                text: 'Mayor a menor',
                value: 'desc'
            },
            {
                text: 'Menor a mayor',
                value: 'asc'
            },
            ],
            startDate: '2025-01-01',
            endDate: today,
            startDateMenu: false,
            endDateMenu: false,
            maxAllowedDate: maxAllowedDate,
            minAllowedDate: minAllowedDate,
            headers: [
            {
                text: 'Banner',
                value: 'banner.image_path',
                align: 'start',
            },
            {
                text: 'Codigo',
                value: 'banner.unique_code',
                align: 'start',
            },
            {
                text: 'Estado',
                value: 'banner.active',
                align: 'start',
            },
            {
                text: 'Idioma',
                value: 'banner.lang',
                align: 'start',
            },
            {
                text: 'Impresiones',
                value: 'statistics.total_impressions',
                align: 'start',
            },
            {
                text: 'Clicks',
                value: 'statistics.clicks',
                align: 'start',
            },
            {
                text: 'Conversiones',
                value: 'statistics.conversions',
                align: 'start',
            },
            {
                text: '% De Click',
                value: 'statistics.click_rate',
                align: 'start',
            },
            {
                text: '% De Conversión',
                value: 'statistics.conversion_rate',
                align: 'start',
            },
            {
                text: '% Impresiones a Conversiones',
                value: 'statistics.impressions_to_conversions_rate',
                align: 'start',
            },
            {
                text: 'Ganancias',
                value: 'statistics.payout',
                align: 'start',
            },
            {
                text: 'Dias activo',
                value: 'statistics.days_active',
                align: 'start',
            },
            {
                text: 'Ultima Conversión',
                value: 'statistics.last_conversion_date',
                align: 'start',
            },
            {
                text: 'Fecha de Creación',
                value: 'banner.created_at',
                align: 'start',
            },
            {
                text: 'Acciones',
                value: 'actions',
                sortable: false,
            },
            
            ],
            statusOptions: [
                { text: 'Todos', value: null },
                { text: 'Activo', value: 1 },
                { text: 'Inactivo', value: 0 }
            ],
            languageOptions: [
                { text: 'Todos', value: null },
                { text: 'Español', value: 'es' },
                { text: 'Inglés', value: 'en' },
                { text: 'Portugués', value: 'pt' },
                { text:'Hindi', value: 'hi' },
                { text: 'Francés', value: 'fr' },
                {text: 'Alemán', value: 'de' },
                { text: 'Italiano', value: 'it' },
                // Añadir otros idiomas según necesites
            ],
            typeOptions: [
                { text: 'Todos', value: null },
                { text: 'Adultos', value: 1 },
                { text: 'Gaming', value: 2 },
                { text: 'Influenciador', value: 3 },
                { text: 'Comercio', value: 4 },
            ],
            ageOptions: [
                { text: 'Todos', value: null },
                { text: '18 - 25 años', value: 1 },
                { text: '26 - 35 años', value: 2 },
                { text: '36 - 50 años', value: 3 },
                { text: '+51 años', value: 4 }
            ],
            genderOptions: [
                { text: 'Todos', value: null },
                { text: 'Mujer', value: 1},
                { text: 'Hombre', value: 2 },
                { text: 'Trans', value: 3 }
            ],

            filterStatus: 1,
            filterLanguage: null,
            filterType: null,
            filterAge: null,
            filterGender: null,
            loadingStatistics: true,
        };
    },
    watch: {
        sortField() {
            if (this.page === 1) {
                this.fetchAdsStats();
            } else {
                this.page = 1; // El watcher de page llamará a fetchAdsStats
            }
        },
        sortOrder() {
            if (this.page === 1) {
                this.fetchAdsStats();
            } else {
                this.page = 1;
            }
        },
        startDate() {
            if (this.page === 1) {
                this.fetchAdsStats();
            } else {
                this.page = 1;
            }
        },
        endDate() {
            if (this.page === 1) {
                this.fetchAdsStats();
            } else {
                this.page = 1;
            }
        }
    },

    created() {
        this.fetchAdsStats();
        this.fetchGlobalStats();
    },

    computed: {
        searchTable: {
            get() { return this.search },
            set: debounce(function (value) {
                this.search = value
                this.fetchAdsStats();
            }, 400)
        }
    },
    methods: {
        showMedia(media) {
            this.selectedLogo = media;
            this.dialog = true;
        },

        openModalUploadBanner() {
            this.showModalUploadBanner = true;
        },
        openModalEditBanner(item) {
            this.selectedBanner = item.banner;
            this.showModalEditBanner = true;
        },
        async fetchAdsStats() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-banners-statistics', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        sort_field: this.sortField,
                        sort_order: this.sortOrder,
                        start_date: this.startDate,
                        end_date: this.endDate,
                        status: this.filterStatus,
                        lang: this.filterLanguage,
                        type: this.filterType,
                        age:  this.filterAge,
                        gender: this.filterGender,
                    },
                });
                this.conversions = response.data.data;
                this.totalConversions = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },


        async fetchGlobalStats() {
            try {
                const response = await axios.get('/admin/get-global-statistics');
                this.trends = response.data.trends || {
                    conversions: 0,
                    clicks: 0,
                    revenue: 0
                };
            } catch (error) {
                console.error(error);
                this.trends = {
                    conversions: 0,
                    clicks: 0,
                    revenue: 0
                };
            } finally {
                this.loadingStatistics = false;
            }
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.fetchAdsStats();
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },

        showSuccess(message) {
            this.$vs.notify({
                title: 'Éxito',
                text: message,
                color: 'success',
                icon: 'check_circle',
                position: 'bottom-center',
                time: 5000,
            });
        },

        showError(error) {
            this.$vs.notify({
                title: 'Error',
                text: error,
                color: 'danger',
                icon: 'error',
                position: 'bottom-center',
                time: 5000,
            });
        },
    },
};
</script>


<style scoped>
.title-desc {
    font-size: 15px;
    color: #676f7b;
}

.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}

.custom-tab {
    text-transform: none;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
}

.scroll-data {
    height: 260px;
    overflow-y: auto;
}

.round-tab {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.title-card {
    color: #616181;
    font-weight: 400;
    font-size: 26px;
}

.d-card {
    background-color: #fff4de;
}

.d-card2 {
    background-color: #dcfce7;
}

.d-card3 {
    background-color: #f4e8ff;
}

.title-dashboard {
    color: #8061c2;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 8px;
}

.desc-dashboard {
    color: gray;
    font-size: 14px;
    margin-left: 10px;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}

.miniatura {
    width: 80px;
    height: 40px;
    overflow: hidden;
}

.clickable-media {
    cursor: pointer;
}

.miniatura img,
.miniatura video {
    width: 100%;
    height: 100%;
}

.dialog-media {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-media .v-dialog {
    box-shadow: none !important;
}

.media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
}
</style>