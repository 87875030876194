<template>
    <v-dialog transition="dialog-top-transition" max-width="1100" v-model="showDialog" persistent eager>
      <v-card class="upload-modal">
        <!-- Header -->
        <v-card-title class="modal-header">
          <h3 class="headline">Editar Banner</h3>
          <v-spacer></v-spacer>
        </v-card-title>
  
        <!-- Contenido principal -->
        <v-card-text class="content-wrapper py-6">
          <v-row>
           <!-- Configuraciones -->
                <v-col cols="12" md="5" class="config-section">
                <div class="config-group">
                    <h4 class="mb-4">Configuraciones de Tipo</h4>
                    <!-- Tipo de banner -->
                    <v-select
                    v-model="bannerType"
                    :items="bannerTypes"
                    :rules="rules.required"
                    color="#8061c2" 
                    label="Categoria de banner"
                    outlined
                    dense
                    ></v-select>

                    <!-- Edad -->
                        <v-select
                        :items="agesTypes"
                        v-model="ageRange"
                        :rules="rules.required"
                        color="#8061c2"
                        label="Edad"
                        outlined
                        dense
                        ></v-select>

                    <!-- Género -->
                    <v-select
                    v-model="selectedGender"
                    :items="genders"
                    :rules="rules.required"
                    color="#8061c2"
                    label="Género"
                    outlined
                    dense
                    ></v-select>
                </div>

                <div class="config-group mt-6">
                    <!-- Campos existentes (URL e Idioma) -->

                    <h4 class="mb-4">Configuraciones de Url/Idioma</h4>
                    <v-text-field
                    v-model="commonUrl"
                    :rules="rules.urlRules"
                    color="#8061c2" 
                    label="URL de destino"
                    placeholder="https://ejemplo.com/pagina-destino"
                    outlined
                    dense
                    class="mt-3"
                    ></v-text-field>

                    <v-select
                    v-model="commonLanguage"
                    :items="languages"
                    :rules="rules.required"
                    color="#8061c2" 
                    label="Idioma"
                    outlined
                    dense
                    class="mt-3"
                    ></v-select>


                    <v-select
                    v-model="bannerStatus"
                    :items="statusItems"
                    :rules="rules.required"
                    color="#8061c2"
                    label="Estado"
                    outlined
                    dense
                    class="mt-3"
                    ></v-select>

                </div>
                </v-col>
            <!-- Zona de visualización de imagen -->
            <v-col cols="12" md="7">
            <v-card class="drop-zone">
                <v-card-text class="drop-content">
                <div v-if="!bannerImage" class="empty-state">
                    <v-icon size="90" color="#8061c2">mdi-image-off</v-icon>
                    <p class="subtitle-1 mt-4 mb-1">No hay imagen seleccionada</p>
                </div>
                <div v-else>
                    <img :src="bannerImage" class="mb-4" style="width: 100%; height: 100%;"/>
                </div>
                </v-card-text>
            </v-card>
            </v-col>
          </v-row>
        </v-card-text>
  
        <!-- Acciones -->
        <v-card-actions class="modal-actions">
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="#8061c2" 
            @click="closeModal"
            class="rounded-button"
          >
            Cancelar
          </v-btn>
            <v-btn 
            color="#8061c2" 
            :disabled="!isFormValid" 
            :loading="loadings"
            @click="handleSubmit"
            depressed
            class="rounded-button"
            style="color: white;"
            >
            Actualizar banner
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

<script>
import axios from '@/axios.js';
export default {
    name: 'ModalEditBanner',
    props: {
        dialogShow: {
            type: Boolean,
            default: false
        },
        bannerData: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            files: [],
            filePreviews: [],
            commonUrl: '',
            useCommonLanguage: false,
            commonLanguage: '',
            ageRange: '',
            bannerType:  '',
            selectedGender:  '',
            bannerImage:  '',
            bannerStatus:  false,
            languages: [
                { text: 'Español', value: 'es' },
                { text: 'Inglés', value: 'en' },
                { text: 'Francés', value: 'fr' },
                { text: 'Alemán', value: 'de' },
                { text: 'Portugués', value: 'pt' },
                {text:  'Hindi', value: 'hi'},
                {text: 'Alemán', value: 'de' },
                { text: 'Italiano', value: 'it' },
            ],
          genders: [
            { text: 'Mujer', value: 1 },
            { text: 'Hombre', value: 2 },
            { text: 'Trans', value: 3 }
          ],
            bannerTypes: [
                { text: 'Adultos', value: 1 },
                { text: 'Gaming', value: 2 },
                { text: 'Influenciador', value: 3 },
                { text: 'Comercio', value: 4 },
            ],
            agesTypes: [
                { text: '18 - 25 años', value: 1 },
                { text: '26 - 35 años', value: 2 },
                { text: '36 - 50 años', value: 3 },
                { text: '+51 años', value: 4 }
            ],
            statusItems: [
                { text: 'Activo', value: true },
                { text: 'Inactivo', value: false }
            ],
            rules: {
                required: [v => v !== null && v !== undefined && v !== '' || 'Este campo es requerido'],
                urlRules: [
                    v => !!v || 'La URL es requerida',
                    v => (v && /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?(\?.*)?(#.*)?$/.test(v)) || 'Ingrese una URL válida'
                ],
            },
            loadings: false
        }

    },

    watch: {
        bannerData: {
            handler(newBanner) {
                this.commonUrl = newBanner?.base_url || '';
                this.commonLanguage = newBanner?.lang || '';
                this.ageRange = newBanner?.age_id || '';
                this.bannerType = newBanner?.banner_type_id || '';
                this.selectedGender = newBanner?.gender_id || '';
                this.bannerImage = newBanner?.image_path || null;
                this.bannerStatus = newBanner?.active === 1; 
            },
            deep: true,
            immediate: true
        }
    },

    computed: {
        showDialog: {
            get() { return this.dialogShow },
            set(value) {
                this.$emit("update:dialogShow", value)
            }
        },

        isFormValid() {
            return (
                this.rules.required[0](this.commonUrl) === true &&
                this.rules.urlRules[1](this.commonUrl) === true &&
                this.rules.required[0](this.commonLanguage) === true &&
                this.rules.required[0](this.bannerType) === true &&
                this.rules.required[0](this.ageRange) === true &&
                this.rules.required[0](this.selectedGender) === true &&
                this.rules.required[0](this.bannerStatus) === true
            );
        },
    },
    methods: {
        clearAllFields() {
            // Limpiar archivos
            this.files = []
            this.filePreviews = []

            // Limpiar campos de configuración
            this.commonUrl = null
            this.commonLanguage = null
            this.ageRange = null
            this.bannerType = null
            this.selectedGender = null
        },
        closeModal() {
            this.showDialog = false
        },
        async handleSubmit() {
            try {
                this.loadings = true
                await this.updatedBanner()
                this.closeModal()
            } catch (error) {
                this.$emit('show-error', error.message)
            } finally {
                this.loadings = false
            }
        },

        async updatedBanner() {
            try {
                const response = await axios.post('/admin/updated-banner', {
                    unique_code: this.bannerData.unique_code,
                    base_url: this.commonUrl,
                    lang: this.commonLanguage,
                    age_id: this.ageRange,
                    banner_type_id: this.bannerType,
                    gender_id: this.selectedGender,
                    active: this.bannerStatus,
                },);

                if (response.data.success) {
                    this.$emit('show-success', 'Banner actualizado correctamente');
                    this.$emit('updated-banner');
                }

            } catch (error) {
                this.$emit('updated-banner');
                const message = error.response?.data?.message || 'Error al subir los banners';
                this.$emit('show-error', message);
            }
        }
    },
}
</script>
<style scoped>
  .modal-header {
    background-color: #8061c2;
    color: white;
    padding: 20px 24px;
  }
  
  .content-wrapper {
    padding: 24px;
  }
  
  .config-section {
    border-right: 1px solid #eee;
    padding-right: 32px;
  }
  
  .config-group {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 16px;
  }
  
  .drop-zone {
    border: 2px dashed #8061c2;
    border-radius: 8px;
    transition: all 0.3s ease;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .drop-zone.dragover {
    background-color: rgba(128, 97, 194, 0.1);
  }
  .empty-state {
      text-align: center;
      height: 100%;
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .file-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .file-list {
    max-height: 430px;
    overflow-y: auto;
    padding-right: 8px;
  }
  
  .file-item {
    background: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px 12px;
  }
  
  .file-name {
    font-weight: 500;
    font-size: 0.875rem;
  }
  
  .file-size {
    font-size: 0.75rem;
    color: #6c757d;
  }
  
  .modal-actions {
    padding: 16px 24px;
    border-top: 1px solid #eee;
  }
  
  .headline {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .age-range {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  margin: 8px 0;
}

.age-range label {
  display: block;
  margin-bottom: 6px;
  font-size: 0.875rem;
}

.drop-content {
    height: 100%;
}

.rounded-button {
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
}
  
</style>